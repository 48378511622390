<template>
	<div>
		<div style="height: 200px;width: 100%;background-color: #FFFFFF;padding-top: 38px;box-sizing: border-box;">
			<div style="display: flex;justify-content: space-between;align-items: center;padding: 0 26px;">
				<div></div>
				<div style="display: flex;align-items: center;width: 300px;">
					<div style="display: flex;align-items: center;flex: 1;justify-content: center;" v-for="(item,index) in nav" :key="index">
						<div style="font-size: 18px;cursor: pointer;" :style="{color:selected == index ? '#6D9FFF' : '#7A7A7A'}" :class="selected == index ? 'add_border' : ''" @click="select(index)">{{item}}</div>
					</div>
				</div>
			</div>
			<div style="display: flex;align-items: center;margin-top: 30px;">
				<div style="margin:0 10px;display: flex;align-items: center;width: 15%;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);background: #FFFFFF;height: 74px;" v-for="(item,index) in nav_img_1" :key="index">
					<div style="display: flex;flex: 1;justify-content: center;align-items: center;flex-direction: column;">
						<img :src="item.img" style="width: 26px;height: 28px;" />
						<div style="color: #808080;font-size: 14px;margin-top: 5px;">{{item.name}}</div>
					</div>
					<div style="display: flex;flex: 1;justify-content: center;align-items: center;flex-direction: column;">
						<div style="color: #527498;font-size: 14px;">{{user_num[index]}}</div>
					</div>
				</div>
				<div style="margin: 0 50px;display: flex;align-items: center;width: 65%;">
					<div style="margin:0 10px;display: flex;align-items: center;width: 34%;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);background: #FFFFFF;height: 74px;" v-for="(item,index1) in nav_img_2" :key="index1">
						<div style="display: flex;flex: 1;justify-content: center;align-items: center;flex-direction: column;">
							<img :src="item.img" style="width: 33px;height: 35px;" />
						</div>
						<div style="display: flex;flex: 1;justify-content: center;align-items: center;flex-direction: column;">
							<div style="color: #298DF7;font-size: 14px;">
								<label style="color: #298DF7;margin-right: 10px;">￥</label>
								{{card_num[index1]}}</div>
						</div>
					</div>
				</div>
				<div style="margin:0 10px;display: flex;align-items: center;width: 15%;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);background: #FFFFFF;height: 74px;" >
					<div style="display: flex;flex: 1;justify-content: center;align-items: center;flex-direction: column;">
						<img :src="all_img" style="width: 26px;height: 28px;" />
						<div style="color: #808080;font-size: 14px;margin-top: 5px;">总支出</div>
					</div>
					<div style="display: flex;flex: 1;justify-content: center;align-items: center;flex-direction: column;">
						<div style="color: #8565FF;font-size: 14px;"> 
						<label style="color: #8565FF;margin-right: 10px;">￥</label>
						{{all_num}}</div>
					</div>
				</div>
			</div>
		</div>
		<div style="margin-top: 15px;background-color: #FFFFFF;padding:40px 20px;">
			<div style="display: flex;justify-content: space-between;align-items: center;">
				<div style="color: #434343;font-size: 18px;">会员卡数据</div>
				<div style="display: flex;align-items: center;width: 300px;">
					<div style="display: flex;align-items: center;flex: 1;justify-content: center;" v-for="(item,index) in nav_echarts" :key="index">
						<div style="font-size: 18px;cursor: pointer;" :style="{color:selected == index ? '#6D9FFF' : '#7A7A7A'}" :class="selected == index ? 'add_border' : ''" @click="select(index)">{{item}}</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 15px;width: 100%;height: 400px;">
				<member-data></member-data>
			</div>
		</div>
		<div style="margin-top: 15px;display: flex;align-items: center;padding-bottom: 10px;">
			<div style="display: flex;width: 40%;height: 100%;background-color: #FFFFFF;flex-direction: column;">
				<div style="color: #434343;font-size: 24px;padding: 20px;height: 40px;">课程数据</div>
				<div style="display: flex;align-items: center;height: 260px;">
					<age-pie></age-pie>
				</div>
				<div style="display: flex;align-items: center;height: 360px;">
					<left-linedate></left-linedate>
				</div>
			</div>
			<div style="display: flex;width: calc(60% - 15px);margin-left: 15px;flex-direction: column;">
				<div style="background-color: #FFFFFF;height: 363px;padding: 20px;">
					<div style="color: #434343;font-size: 24px;">机构数据  <span style="color: #808080;font-size: 14px;margin-left: 30px;">在线机构数</span>  <span style="color: #FF3131;font-size: 20px;" v-if="mechanism_new[0]">{{mechanism_new[0][0]}}</span> </div>
					<div style="display: flex;align-items: center;justify-content: center;margin: 0 50px;">
						<div style="display: flex;margin-top: 30px;align-items: center;justify-content: center;flex-direction: column;flex: 1;" >
							<div style="color: #4FC5EA;font-size: 20px;" v-if="mechanism_new[0]">{{mechanism_new[0][1]}}</div>
							<div style="color: #808080;font-size: 14px;margin-top: 10px;">新增机构数</div>
						</div>
						<div style="display: flex;margin-top: 30px;align-items: center;justify-content: center;flex-direction: column;flex: 1;" >
							<div style="color: #FB7D02;font-size: 20px;" v-if="mechanism_new[0]">{{mechanism_new[0][2]}}</div>
							<div style="color: #808080;font-size: 14px;margin-top: 10px;">新增课程</div>
						</div>
						<div style="display: flex;margin-top: 30px;align-items: center;justify-content: center;flex-direction: column;flex: 1;" >
							<div style="color: #5DAB89;font-size: 20px;" v-if="mechanism_new[0]">{{mechanism_new[0][3]}}</div>
							<div style="color: #808080;font-size: 14px;margin-top: 10px;">新增教练</div>
						</div>
					</div>
					<div style="margin-top: 10px;height: 245px;">
						<right-linedate v-if="mechanism_new.length>0" :mechanism_data="mechanism_new"></right-linedate>
					</div>
				</div>
				<div style="display: flex;width: 100%;height: 282px;margin-top: 15px;">
					<div style="flex: 1;background-color: #FFFFFF;height: calc(100% - 40px);padding: 20px;">
						<div style="color: #434343;font-size: 24px;">商城数据</div>
						<div style="display: flex;height: calc(100% - 30px);align-items: center;">
							<div style="display: flex;flex-direction: column;align-items: center;flex: 1;" v-for="(item,index) in right_shop_data" :key="index">
								<img :src="item.img" style="width: 47px;height: 41px;" />
								<div style="color: #808080;font-size: 12px;margin-top: 20px;">{{item.name}}</div>
								<div style="color: #1296DB;font-size: 14px;margin-top: 20px;">{{shop_data[index]}}</div>
							</div>
						</div>
					</div>
					<div style="flex: 1;background-color: #FFFFFF;margin-left: 15px;height: calc(100% - 40px);padding: 20px;">
						<div style="color: #434343;font-size: 24px;">合作商数据</div>
						<div style="display: flex;height: calc(100% - 30px);flex-direction: column;">
							<div style="display: flex;align-items: center;flex: 1;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);background-color: #FFFFFF;margin-top: 10px;padding: 0 20px;">
								<img :src="img_1" style="width: 40px;height: 31px;" />
								<div style="color: #808080;font-size: 12px;margin-left: 20px;width: 60px;">买卡数据</div>
								<div style="color: #67C89E;font-size: 15px;margin-left: 20px;">{{partner_data[0]}}</div>
							</div>
							<div style="display: flex;align-items: center;flex: 1;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);background-color: #FFFFFF;margin-top: 10px;padding: 0 20px;">
								<img :src="img_2" style="width: 32px;height: 36px;" />
								<div style="color: #808080;font-size: 12px;margin-left: 20px;width: 68px;">新增合作商</div>
								<div style="color: #527498;font-size: 15px;margin-left: 20px;">{{partner_data[1]}}</div>
							</div>
							<div style="display: flex;align-items: center;flex: 1;box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);background-color: #FFFFFF;margin-top: 10px;padding: 0 20px;">
								<img :src="img_3" style="width: 35px;height: 35px;" />
								<div style="color: #808080;font-size: 12px;margin-left: 20px;width: 65px;">激活卡数</div>
								<div style="color: #1296DB;font-size: 15px;margin-left: 20px;">{{partner_data[2]}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MemberData from '../../components/dataBoard/MemberData.vue'
	import AgePie from '../../components/dataBoard/age_pie.vue'
	import LeftLinedate from '../../components/dataBoard/left_dateline.vue'
	import RightLinedate from '../../components/dataBoard/right_dateline.vue'
export default {
  data() {
    return {
		all_img:"http://img.curiousmore.com/827695",
		nav:['今日','本周','本月','总数'],
		nav_echarts:['新售','激活','续卡','本月到期'],
		selected:0,
		nav_img_1:[
			{
				img:"http://img.curiousmore.com/998478",
				name:"新增用户"
			},{
				img:"http://img.curiousmore.com/42296",
				name:"在线用户"
			},
		],
		nav_img_2:[
			{
				img:"http://img.curiousmore.com/195578",
				name:"新增用户"
			},{
				img:"http://img.curiousmore.com/422813",
				name:"在线用户"
			},{
				img:"http://img.curiousmore.com/114864",
				name:"在线用户"
			},{
				img:"http://img.curiousmore.com/16059",
				name:"在线用户"
			},
		],
		start_time:'',
		user_num:[],
		card_num:[],
		all_num:0,
		right_shop_data:[
			{
				img:"http://img.curiousmore.com/507490",
				name:"下单"
			},{
				img:"http://img.curiousmore.com/977579",
				name:"待发货"
			},{
				img:"http://img.curiousmore.com/255545",
				name:"待收货"
			},{
				img:"http://img.curiousmore.com/612976",
				name:"已收货"
			},
		],
		right_Partner_data:[],
		img_1:"http://img.curiousmore.com/548876",
		img_2:"http://img.curiousmore.com/998478",
		img_3:"http://img.curiousmore.com/383027",
		mechanism_new:[],
		shop_data:[],
		partner_data:[]
    }
  },
  components:{
	  MemberData,
	  AgePie,
	  LeftLinedate,
	  RightLinedate
  },
  mounted() {
	  this.start_time = new Date().format("yyyy-MM-dd") + ' 00:00:00';
	  this.get_up_data(this.start_time)
	  this.get_center_data(this.start_time)
	  this.get_shop_data()
	  this.get_partner_data()
  },
  methods: {
	  getFirstDayOfWeek (date) {
	      var day = date.getDay() || 7;
	      return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1 - day).format("yyyy-MM-dd");
	  },
	  getCurrentMonthFirst(){
	          var date = new Date();
	          date.setDate(1);
	          var month = parseInt(date.getMonth()+1);
	          var day = date.getDate();
	          if (month < 10) {
	              month = '0' + month
	          }
	          if (day < 10) {
	              day = '0' + day
	          }
	          return date.getFullYear() + '-' + month + '-' + day + ' 00:00:00';
	      },
	  select(index){
		  this.selected = index
		  if(index == 0){
			   this.start_time = new Date().format("yyyy-MM-dd") + ' 00:00:00'
		  }
		  if(index == 1){
			  this.start_time = this.getFirstDayOfWeek(new Date()) + ' 00:00:00'
		  }
		  if(index == 2){
			  this.start_time = this.getCurrentMonthFirst()
		  }
		  this.get_up_data(this.start_time)
	  },
	  get_up_data(start_time){
		  let url ='user/mastermechanism/queryMechanismStats'
		  this.$axios.get(url,{
		      params:{
				  start_time:start_time
		      }
		  }).then(res=>{
			  this.user_num = res.data.data.slice(0,2)
			  this.card_num = res.data.data.slice(2,6)
			  this.all_num = res.data.data[res.data.data.length - 1]
		  })
	  },
	  get_center_data(start_time){
	  		  let url ='user/mastermechanism/queryMechanism7DayNew'
	  		  this.$axios.get(url,{
	  		      params:{
	  				  start_time:start_time
	  		      }
	  		  }).then(res=>{
	  			  this.mechanism_new = res.data.data
	  		  })
	  },
	  get_shop_data(){
	  		  let url ='user/mastermechanism/queryMechanismShop'
	  		  this.$axios.get(url,{
	  		      params:{
	  				  
	  		      }
	  		  }).then(res=>{
	  			  this.shop_data = res.data.data
	  		  })
	  },
	  get_partner_data(){
	  		  let url ='user/mastermechanism/queryMechanismPartner'
	  		  this.$axios.get(url,{
	  		      params:{
	  				  
	  		      }
	  		  }).then(res=>{
	  			  this.partner_data = res.data.data  
	  		  })
	  },
	  
  },
}
</script>

<style>
	.add_border{
		border-bottom: 1px solid #6D9FFF;
	}
</style>
