<template>
	<div class="talent-container">
		<div style="display: flex;width: 100%;">
			<div class="chart" id="chart_rightline1"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		name: "talent",
		data() {
			return {
				time: "",
				cityData: [],
				tdd: 0,
				intervalid1: null,
				weektime: []
			};
		},
		watch: {

		},
		mounted() {
			this.get_left_data();
		},
		methods: {
			get_left_data() {
				let url = 'user/mastermechanism/queryMechanismPay7Day'
				this.$axios.get(url, {
					params: {
						start_time: new Date().format("yyyy-MM-dd hh:mm:ss")
					}
				}).then(res => {
					this.getEchartLeft2(chart_rightline1, res.data.data);
				})
			},

			getEchartLeft2(dom, realdata) {
				var dom = dom; //就是你要放入的盒子元素
				this.myChart = echarts.init(dom);
				let lineY = [];
				let option = {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data: ['新增课程', '约课数据', '核销数据'],
						bottom: "2%"
					},

					grid: {
						left: '3%',
						right: '5%',
						bottom: '12%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: realdata[realdata.length - 1]
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							data: realdata[0],
							type: 'line',
							name: "新增课程",
							lineStyle: {
								color: "#2678FF"
							},
							// symbolSize: 13,
							// symbol: "circle"
						},
						{
							data: realdata[1],
							type: 'line',
							name: "约课数据",
							lineStyle: {
								color: "#F6CD6C"
							},
							// symbolSize: 13,
							// symbol: "circle"
						},
						{
							data: realdata[2],
							type: 'line',
							name: "核销数据",
							lineStyle: {
								color: "#FD81A7"
							},
							// symbolSize: 13,
							// symbol: "circle"
						}
					]

				};

				this.myChart.setOption(option, true);
				window.addEventListener("resize", () => {
					this.myChart.resize();
				});
			},
		},
		beforeDestroy() {},
	};
</script>

<style lang="less" scoped>
	.talent-container {
		display: flex;
		width: 100%;

		.chart {
			height: 360px;
			width: 100%;
		}

		.huanhang {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

	}
</style>
