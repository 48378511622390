<template>
		<div style="width: 100%;">
			<div class="talent-container">
				<div class="chart" style="width: 50%;" id="chart_lefthyuan1"></div>
				<div class="chart" style="width: 50%;" id="chart_lefthyuan2"></div>
			</div>
			
			<div style="display: flex;">
				<div style="flex: 1;display: flex;align-items: center;justify-content: center;">年龄分布</div>
				<div style="flex: 1;display: flex;align-items: center;justify-content: center;">课程类别分布</div>
			</div>
		</div>
		
	
</template>

<script>
	var app = {
		currentIndex: -1
	}
	import * as echarts from 'echarts';
	export default {
		name: "talent",
		props: {},
		watch: {

		},
		data() {
			return {
				time: "",
				cityData: [],
				option: {},
				myChart: null,
				timer: '',
			};
		},
		mounted() {
			this.GetAmoutdata();
		},
		methods: {
			GetAmoutdata() {
				let url = 'user/mastermechanism/queryMechanismPie'
				this.$axios.get(url, {
					params: {
						
					}
				}).then(res => {
					for (let i = 1; i < 3; i++) {
						let gg = "chart_lefthyuan" + i;
						let j = i - 1;
						this.getEchartLeft2(gg, res.data.data[j], 0);
					}
				})
			},
			getEchartLeft2(dom, realdata, ttl) {
				var dom = dom; //就是你要放入的盒子元素
				var chartDom = document.getElementById(dom);
				this.myChart = echarts.init(chartDom);
				
				this.option = {
					// color: ["#9C33E4", "#0571EA", "#26BAFF", "#DC3169", "#0FA69E"],
					legend: {
						show: false
					},
					tooltip: {
						trigger: 'item'
					},
					series: [{
						name: '',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						label: {
							show: false,
							position: 'center'
						},
						itemStyle: {
							emphasis: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							},
							normal: {
								color: function(params) {
									//自定义颜色
									var colorList = [
										'#0571EA', '#0FA69E', '#15B5FE', '#DC3169', '#9C33E4'
									];
									return colorList[params.dataIndex]
								}
							}
						},
						emphasis: {
							label: {
								show: false,
							}
						},
						labelLine: {
							show: false
						},
						data: realdata
					}]
				};

				this.myChart.setOption(this.option, true);
				window.addEventListener("resize", () => {
					this.myChart.resize();
				});
			},
		},
		beforeDestroy() {
			// clearInterval(this.timer)
		},
	};
</script>

<style lang="less" scoped>
	.talent-container {
		display: flex;
		align-items: center;
		width: 100%;

		.chart {
			height: 260px;
		}

		.huanhang {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
</style>
