<template>
	<div class="talent-container">
		<div style="display: flex;width: 100%;">
			<div class="chart" id="chart_rightline2"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		name: "talent",
		props: {
			mechanism_data: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				time: "",
				cityData: [],
				tdd: 0,
				intervalid1: null,
				weektime: []
			};
		},
		watch: {

		},
		mounted() {
			// this.get_left_data();
			this.$nextTick(function() {
				this.getEchartLeft2(chart_rightline2, this.mechanism_data);
			})
		},
		methods: {
			get_left_data() {
				let url = 'user/mastermechanism/queryMechanismPay7Day'
				this.$axios.get(url, {
					params: {
						start_time: new Date().format("yyyy-MM-dd hh:mm:ss")
					}
				}).then(res => {
					// this.card_list = res.data.data
					this.getEchartLeft2(chart_rightline2, res.data.data);
				})
			},

			getEchartLeft2(dom, realdata) {
				var dom = dom; //就是你要放入的盒子元素
				this.myChart = echarts.init(dom);
				let lineY = [];
				let option = {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						show: false,
						data: ['新增课程', '约课数据', '核销数据'],
						bottom: "0%"
					},

					grid: {
						top: '5%',
						left: '3%',
						right: '4%',
						bottom: '8%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						data: realdata[realdata.length - 1]
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							data: realdata[1],
							type: 'line',
							name: "新增机构数"
						},
						{
							data: realdata[2],
							type: 'line',
							name: "新增课程"
						},
						{
							data: realdata[3],
							type: 'line',
							name: "新增教练"
						}
					]

				};

				this.myChart.setOption(option, true);
				window.addEventListener("resize", () => {
					this.myChart.resize();
				});
			},
		},
		beforeDestroy() {},
	};
</script>

<style lang="less" scoped>
	.talent-container {
		display: flex;
		width: 100%;

		.chart {
			height: 245px;
			width: 100%;
		}

		.huanhang {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

	}
</style>
