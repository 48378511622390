<template>
	<div class="righttop" ref="charts"></div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		name: "cakeLinkage",
		props: {},
		data() {
			return {
				// aanum: 0,
				option: null,
				dataMap: [10, 30, 35, 40, 10],
				url: "/user/userAppointment/queryCourseNowUserDetail",
				card_list: []
			};
		},
		mounted() {
			// console.log(this.aanum);
			// this.GetCourseDetail();

			this.get_center_data();
		},
		methods: {
			get_center_data() {
				let url = 'user/mastermechanism/queryMechanismColumn7Day'
				this.$axios.get(url, {
					params: {
						start_time: new Date().format("yyyy-MM-dd hh:mm:ss")
					}
				}).then(res => {
					this.card_list = res.data.data
					this.mycharts();
				})
			},
			mycharts() {
				let myChart = echarts.init(this.$refs.charts, "macarons");
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						show: false
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: this.card_list[this.card_list.length - 1]
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [
						// {
						// 	name: '总数',
						// 	type: 'bar',
						// 	barGap: '-100%', // 左移100%，stack不再与上面两个在一列
						// 	label: {
						// 		normal: {
						// 			show: true,
						// 			position: 'top', //  位置设为top
						// 			formatter: '{c}',
						// 			textStyle: {
						// 				color: '#000'
						// 			}
						// 		}
						// 	},
						// 	itemStyle: {
						// 		normal: {
						// 			color: 'rgba(128, 128, 128, 0.3)' // 仍为透明
						// 		}
						// 	},
						// 	data: this.card_list[12],
						// },
						{
							name: '合作商尊享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'zun',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[0],
							color: '#316FB0'
						},
						{
							name: '用户尊享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'zun',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[1],
							color: '#4FC5EA'
						},
						{
							name: '发放尊享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'zun',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[2],
							color: '#298DF7'
						},
						{
						name: '尊享卡总数', 
						type: 'bar', 
						barWidth: 25,
						stack: 'zun', 
						label: {
						      show: true,
							  color:'#298DF7',
							  position: "insideBottom"
						    },
							itemStyle: {
							      color: "rgba(193, 60, 60, 0)"
							    },
						data: this.card_list[12]
						},
						{
							name: '合作商专享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'zhuan',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[3],
							color: '#2996B8'
						},
						{
							name: '用户专享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'zhuan',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[4],
							color: '#97D9BC'
						},
						{
							name: '发放专享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'zhuan',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[5],
							color: '#4FC5EA'
						},
						{
						name: '专享卡总数', 
						type: 'bar', 
						barWidth: 25,
						stack: 'zhuan', 
						label: {
						      show: true,
							  color:'#4FC5EA',
							  position: "insideBottom"
						    },
							itemStyle: {
							      color: "rgba(193, 60, 60, 0)"
							    },
						data: this.card_list[13]
						},
						{
							name: '合作商优学卡',
							type: 'bar',
							barWidth: 25,
							stack: 'you',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[6],
							color: '#5DAB89'
						},
						{
							name: '用户优学卡',
							type: 'bar',
							barWidth: 25,
							stack: 'you',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[7],
							color: '#4FC5EA'
						},
						{
							name: '发放优学卡',
							type: 'bar',
							barWidth: 25,
							stack: 'you',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[8],
							color: '#97D9BC'
						},
						{
						name: '优学卡总数', 
						type: 'bar', 
						barWidth: 25,
						stack: 'you', 
						label: {
						      show: true,
							  color:'#97D9BC',
							  position: "insideBottom"
						    },
							itemStyle: {
							      color: "rgba(193, 60, 60, 0)"
							    },
						data: this.card_list[14]
						},
						{
							name: '合作商优学尊享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'youz',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[9],
							color: '#F3BC86'
						},
						{
							name: '用户优学尊享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'youz',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[10],
							color: '#E99746'
						},
						{
							name: '发放优学尊享卡',
							type: 'bar',
							barWidth: 25,
							stack: 'youz',
							emphasis: {
								focus: 'series'
							},
							data: this.card_list[11],
							color: '#FF7E00'
						},
						{
						name: '优学尊享卡总数', 
						type: 'bar', 
						barWidth: 25,
						stack: 'youz', 
						label: {
						      show: true,
							  color:'#FF7E00',
							  position: "insideBottom"
						    },
							itemStyle: {
							      color: "rgba(193, 60, 60, 0)"
							    },
						data: this.card_list[15]
						},
					]
				};
				myChart.setOption(option);
				//让图表自适应
				window.addEventListener("resize", function() {
					myChart.resize(); // myChart 是实例对象
				});
			},
		},
	};
</script>

<style scoped>
	.righttop {
		width: 100%;
		height: 400px;
		/* margin-top: 10px; */
	}
</style>
